import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getSetupByGPName } from "../../apiServices/setupApi";
import "./SetupByGP.css";
import Loader from "../../components/Loader/Loader";

function SetupByGPPage() {
  const { gpName, raceName } = useParams();
  const [results, setResults] = useState([]);
  const [setupData, setSetupData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error] = useState(null);

    // États pour les filtres
    const [selectedTeam, setSelectedTeam] = useState("");
    const [selectedCondition, setSelectedCondition] = useState("");
    const [selectedController, setSelectedController] = useState("");

      // États pour la pagination
  const [currentPage, setCurrentPage] = useState(1);
  const setupsPerPage = 10; // Nombre de setups par page


  useEffect(() => {
    const fetchSetupData = async () => {
        try {
            console.log("gpName1", gpName);
          const data = await getSetupByGPName(gpName);

          // Trier les données par nombre de "likes" décroissant
          const sortedData = data.sort((a, b) => b.Likes - a.Likes);

          setSetupData(sortedData);
          console.log(sortedData);
          setLoading(false);
        } catch (err) {
            console.error("Error fetching setup:", error);
            setLoading(false);
        }
      };

      fetchSetupData();
  }, [gpName]);


  // Filtrage des données
  const filteredData = setupData.filter((item) => {
    return (
      (selectedTeam === "" || item.TeamNom === selectedTeam) &&
      (selectedCondition === "" || item.ConditionNom === selectedCondition) &&
      (selectedController === "" || item.ControllerNom === selectedController)
    );
  });

    // Pagination - calcul des setups à afficher
    const indexOfLastSetup = currentPage * setupsPerPage;
    const indexOfFirstSetup = indexOfLastSetup - setupsPerPage;
    const currentSetups = filteredData.slice(indexOfFirstSetup, indexOfLastSetup);
  
    const nextPage = () => {
      if (currentPage < Math.ceil(filteredData.length / setupsPerPage)) {
        setCurrentPage(currentPage + 1);
      }
    };
  
    const prevPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
    // Changer de page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
  
    const totalPages = Math.ceil(filteredData.length / setupsPerPage);

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="error">Error: Loading Setups</div>
    );
  }

  return (
    <div className="results-container">
      <h1>F1 24 - {raceName} Setups</h1>
       <div className="filters-container">
        <label>
          <select
            value={selectedTeam}
            onChange={(e) => setSelectedTeam(e.target.value)}
          >
            <option value="">All Teams</option>
            <option value="Mercedes">Mercedes</option>
            <option value="Red Bull Racing">Red Bull Racing</option>
            <option value="Ferrari">Ferrari</option>
            <option value="McLaren">McLaren</option>
            <option value="Alpine">Alpine</option>
            <option value="Aston Martin">Aston Martin</option>
            <option value="AlphaTauri">RedBull</option>
            <option value="Alfa Romeo">Sauber</option>
            <option value="Haas">Haas</option>
            <option value="Williams">Williams</option>
          </select>
        </label>

        <label>
          <select
            value={selectedCondition}
            onChange={(e) => setSelectedCondition(e.target.value)}
          >
            <option value="">All Conditions</option>
            <option value="Dry">Dry</option>
            <option value="Wet">Wet</option>
            <option value="Mixed">Mixed</option>
          </select>
        </label>

        <label>
          <select
            value={selectedController}
            onChange={(e) => setSelectedController(e.target.value)}
          >
            <option value="">All Controllers</option>
            <option value="Wheel">Wheel</option>
            <option value="Controller">Controller</option>
          </select>
        </label>
      </div>

      <table className="results-table">
        <thead>
          <tr>
            <th>User</th>
            <th>Team</th>
            <th>Condition</th>
            <th>Controller</th>
            <th>Like</th>
          </tr>
        </thead>
        <tbody>
          {currentSetups.map((item) => (
            <tr key={item.Like}>
              <td>
              <Link to={`/setup/${item.ID[0]}`}>{item.UserEmail}</Link> 
              </td>
              <td>
                {item.TeamNom}
              </td>
              <td>{item.ConditionNom}</td>
              <td>{item.ControllerNom}</td>
              <td>{item.Likes}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="pagination">
        <button onClick={prevPage} disabled={currentPage === 1}>
          Previous Page
        </button>
        <span className="current-page">Page {currentPage}</span>
        <button
          onClick={nextPage}
          disabled={currentPage === Math.ceil(filteredData.length / setupsPerPage)}
        >
          Next Page
        </button>
      </div>
    </div>
  );
}

export default SetupByGPPage;

import "./App.css";
import "./assets/global.css";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";
import "./assets/fonts/Formula1-Bold.otf";
import "./assets/fonts/Formula1-Regular.otf";
import "./assets/fonts/Formula1-Wide.otf";
import DriverStandings from "./pages/DriverStandings/DriverStandings";
import ConstructorStandings from "./pages/ConstructorStandings/ConstructorStandings";
import Schedule from "./pages/Schedule/Schedule";
import DriverProfile from "./components/DriverProfile/DriverProfile";
import ConstructorsProfile from "./components/ConstructorsProfile/ConstructorsProfile";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import RedirectToLandingPage from "./components/RedirectToLandingPage/RedirectToLandingPage";
import RacesPage from "./components/RacesPage/RacesPage";
import RaceResultsPage from "./components/RaceResultsPage/RaceResultsPage";
import SprintResultsPage from "./components/SprintResultsPage/SprintResultPage";
import QualifyingResultsPage from "./components/QualifyingResults/QualifyingResults";
import QualifyingPage from "./components/QualifyingPage/QualifyingPage";
import SetupByGPPage from "./components/SetupByGP/SetupByGP";
import ProfilePage from "./components/Profile/Profile";
import CreateSetupPage  from "./components/CreateSetup/CreateSetup";
import EditSetupPage from "./components/EditSetup/EditSetup";
import SetupByIDPage from "./components/SetupByID/SetupByID";
import NewsF1 from "./pages/NewsF1/NewsF1";
import Setups from "./pages/SetupsPage/Setups";
import LegalNotices from "./pages/LegalNotices/LegalNotices"
import { useEffect } from 'react';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import { b2cPolicies, protectedResources } from './authConfig';
import { compareIssuingPolicy } from './utils/claimUtils';
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";

const Pages = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal();
  useEffect(() => {
      const callbackId = instance.addEventCallback((event) => {
          if (
              (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
              event.payload.account
          ) {
              /**
               * For the purpose of setting an active account for UI update, we want to consider only the auth
               * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
               * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
               * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
               */
              if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.editProfile)) {
                  // retrieve the account from initial sing-in to the app
                  const originalSignInAccount = instance
                      .getAllAccounts()
                      .find(
                          (account) =>
                              account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                              account.idTokenClaims.sub === event.payload.idTokenClaims.sub && 
                              compareIssuingPolicy(account.idTokenClaims, b2cPolicies.names.signUpSignIn)        
                      );

                  let signUpSignInFlowRequest = {
                      authority: b2cPolicies.authorities.signUpSignIn.authority,
                      account: originalSignInAccount,
                  };

                  // silently login again with the signUpSignIn policy
                  instance.ssoSilent(signUpSignInFlowRequest);
              }

              /**
               * Below we are checking if the user is returning from the reset password flow.
               * If so, we will ask the user to reauthenticate with their new password.
               * If you do not want this behavior and prefer your users to stay signed in instead,
               * you can replace the code below with the same pattern used for handling the return from
               * profile edit flow
               */
              if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
                  let signUpSignInFlowRequest = {
                      authority: b2cPolicies.authorities.signUpSignIn.authority,
                      scopes: [
                          ...protectedResources.apiTodoList.scopes.read,
                          ...protectedResources.apiTodoList.scopes.write,
                      ],
                  };
                  instance.loginRedirect(signUpSignInFlowRequest);
              }
          }

          if (event.eventType === EventType.LOGIN_FAILURE) {
              // Check for forgot password error
              // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
              if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                  const resetPasswordRequest = {
                      authority: b2cPolicies.authorities.forgotPassword.authority,
                      scopes: [],
                  };
                  instance.loginRedirect(resetPasswordRequest);
              }
          }
      });

      return () => {
          if (callbackId) {
              instance.removeEventCallback(callbackId);
          }
      };
      // eslint-disable-next-line
  }, [instance]);

  return (
    <div>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/driverstandings" element={<DriverStandings />} />
        <Route path="/newsF1" element={<NewsF1 />} />
        <Route
          path="/constructorstandings"
          element={<ConstructorStandings />}
        />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="*" element={<RedirectToLandingPage />} />
        <Route path="/legalnotices" element={<LegalNotices />} />
        <Route path="/driver/:id" element={<DriverProfile />} />
        <Route path="/constructor/:id" element={<ConstructorsProfile />} />
        <Route path="/race" element={<RacesPage />} />
        <Route path="/race/:race/:raceName" element={<RaceResultsPage />} />
        <Route path="/sprint/:round/:raceName" element={<SprintResultsPage />} />
        <Route path="/qualifying/:round/:raceName" element={<QualifyingResultsPage />} />
        <Route path="/qualifying/" element={<QualifyingPage />} />
        <Route path="/setup" element={<Setups />} />
        <Route path="/setup/:gpName/:raceName" element={<SetupByGPPage />} />
        <Route path="/createSetup" element={<CreateSetupPage  />} />
        <Route path="/setup/:id" element={<SetupByIDPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/setup/edit/:id" element={<EditSetupPage />} />
      </Routes>
    </div>
  );
};

/**
* msal-react is built on the React context API and all parts of your app that require authentication must be
* wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
* then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
* PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
*/
const App = ({ instance }) => {
  return (
    console.log("instance", instance),
      <MsalProvider instance={instance}>
        <NavBar />
              <Pages />
        <Footer />
      </MsalProvider>
  );
};

export default App;


import React from "react";
import { Link } from "react-router-dom";
function ErgastApi() {
  return (
    <>
    <a
      className="ergastLink"
      href="https://github.com/jolpica/jolpica-f1"
      target="_blank"
      rel="noopener noreferrer"
    >
      This is possible thanks to{" "}
      <span className="Ergast">Jolpica</span>
    </a>
    <Link className="ergastLink" to={"/legalnotices"}>Terms & Conditions</Link>
    </>
  );
}

export default ErgastApi;

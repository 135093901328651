import React, { useEffect } from 'react';
import './LegalNotices.css';
function LegalNotices() {
  useEffect(() => {
    document.title = 'Terms & Conditions'; 
  }, []);
  return (
    <div className="legal-notices-container">
      <h1 className="legal-title">Terms & Conditions</h1>
      <section className="legal-section">
        <h2>1. General Information</h2>
        <p>
          This website is intended to provide information and resources related
          to Formula 1 (F1) and the F1 gaming community. By accessing this
          website, you agree to the terms and conditions outlined in these Legal
          Notices.
        </p>
        <h3>API Usage and Data Sources</h3>
        <p>
          The data displayed on this website, including race results, standings, and driver/team information, is retrieved from the 
          <a href="https://github.com/jolpica/jolpica-f1" target="_blank" rel="noopener noreferrer"> Jolpica F1 API</a>. 
          This API provides free, publicly available data for Formula 1, and all content is used purely for informational purposes. 
          We do not own any of the data and claim no copyright over any Formula 1-related information.
        </p>
        <p>
          The data is not modified or altered in any way, and the information presented reflects official race results and statistics as 
          provided by the Jolpica API. For more information about the API and its terms of use, please refer to the 
          <a href="https://github.com/jolpica/jolpica-f1" target="_blank" rel="noopener noreferrer"> official Jolpica website</a>.
        </p>
      </section>
      <section className="legal-section">
        <h2>2. Intellectual Property Rights</h2>
        <p>
          All content presented on this website, including but not limited to
          text, graphics, logos, images, and data, is either owned by the
          website operator or is used under legal provisions outlined under
          French law, specifically under <em>Article L.122-5 of the French
          Intellectual Property Code</em>.
        </p>
        <blockquote>
          According to Article L.122-5 of the French Intellectual Property Code:
          <br />
          “When a work has been disclosed, the author may not prohibit the
          reproduction or representation of excerpts of the work, provided that
          the reproduction or representation is intended solely for private use
          or is made for educational, research, or scientific purposes, under
          the condition that it is explicitly referenced and does not infringe
          upon normal exploitation of the work or unjustifiably harm the
          author's rights.”
        </blockquote>
        <p>
          Any logos, trademarks, and intellectual property owned by third
          parties displayed on this website are used strictly for illustration
          and informational purposes, primarily to enhance user experience and
          support research, education, or discussion in the Formula 1 domain.
        </p>
      </section>
      <section className="legal-section">
        <h2>3. Copyright and Trademarks</h2>
        <p>
          All logos, trademarks, and images used on this website, especially
          those related to Formula 1 teams, car manufacturers, races, circuits,
          and related organizations, remain the exclusive property of their
          respective owners. These materials are used only for illustrative
          purposes under <em>Article L.122-5</em> of the <em>French Intellectual
          Property Code</em> and applicable international intellectual property
          laws.
        </p>
        <p>
          If you are the owner of a logo, image, or trademark that is featured
          on our site and would like it removed, please contact us at
          contact@example.com, and we will comply with your request as soon as
          possible.
        </p>
      </section>
      <section className="legal-section">
        <h2>4. Limitation of Liability</h2>
        <p>
          While we strive to provide accurate and up-to-date information, we
          cannot guarantee that all information on this website is entirely
          accurate or complete. The content is provided "as is" without any
          warranty of any kind.
        </p>
        <p>
          We do not own any of the logos or trademarks displayed on this
          website. These elements are presented solely for the purpose of
          illustration and are used within the framework of French copyright law
          for research, educational, or informational purposes. We do not
          generate revenue from the use of these materials, nor do we suggest
          any affiliation or endorsement by the trademark owners.
        </p>
      </section>
      <section className="legal-section">
        <h2>5. Governing Law</h2>
        <p>
          These terms & conditions are governed by French law. Any disputes arising
          from the use of this website or concerning its content will be
          resolved in accordance with the applicable laws of France.
        </p>
      </section>
      <section className="legal-section">
      <h2>6. Contact Information</h2>
        <p>
        For any questions regarding terms & conditions or if you wish to request the removal of any copyrighted data or your account, please contact us at: 
          <a href="mailto:alan.audran@ynov.com"> alan.audran@ynov.com</a>. 
          We will ensure that all your data is fully deleted upon request.
          </p>
      </section>
    </div>
  );
}
export default LegalNotices;